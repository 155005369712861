export default [
  {
    path: '/hold-banned-users',
    name: 'hold-banned-users',
    component: () => import('@/views/apps/hold-banned-users/HoldBannedUsersView.vue'),
    meta: {
      requiredAuth: true,
      permission: 'finance-payment-methods-page',
      pageTitle: 'Banned Hold Users',
      breadcrumb: [
        {
          text: 'Banned Hold Users',
        },
      ],
    },
  },
]
